<template>
  <div id="vehicle-configuration">
    <div class="page-title px-4 mb-4">
      <h2>
        <span class="me-2">Vehicle {{ vehicleId }}</span>
      </h2>
      <save-button v-if="canSave" :save-action="saveVehicleSettings" />
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>
    <v-row v-if="!loading">
      <v-col cols="12" md="5" lg="4">
        <vehicle-info-panel />
        <vehicle-screenshot-panel :vehicle-id="vehicleId" />
      </v-col>
      <v-col cols="12" md="7" lg="8">
        <vehicle-overview ref="vehicleOverview" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiBus } from "@mdi/js"
import { getCurrentInstance } from "@vue/composition-api/dist/vue-composition-api"
import useAppConfig from "@core/@app-config/useAppConfig"
import VehicleInfoPanel from "./components/VehicleInfoPanel.vue"
import VehicleScreenshotPanel from "./components/VehicleScreenshotPanel.vue"
import VehicleOverview from "./components/VehicleOverview.vue"
import SaveButton from "../components/SaveButton.vue"

import store from "@/store"

export default {
  name: "VehicleConfiguration",

  metaInfo() {
    return {
      title: "Edit Vehicle",
    }
  },

  components: {
    VehicleInfoPanel,
    VehicleScreenshotPanel,
    VehicleOverview,
    SaveButton,
  },

  async created() {
    await store.dispatch("getVehicle", this.vehicleId)

    this.loading = false
  },

  beforeDestroy() {
    clearInterval(this.liveUpdate)
  },

  methods: {
    saveVehicleSettings() {
      if (this.canSave) {
        this.$refs.vehicleOverview.saveVehicleSettings()
      }
    },
  },

  setup() {
    const curr = getCurrentInstance()
    const vehicleId = curr.proxy.$route.params.id
    const canSave = curr.proxy.$auth.hasScope("vehicles:edit")

    const { refreshIntervalVehicles } = useAppConfig()

    const liveUpdate = setInterval(async () => {
      await store.dispatch("getVehicleStatus", vehicleId)
    }, refreshIntervalVehicles * 1000)

    if (!vehicleId) {
      curr.proxy.$router.push({
        name: "next-stop-vehicles",
      })
    }

    return {
      vehicleId,
      liveUpdate,
      loading: true,
      canSave,
      icons: {
        mdiBus,
      },
    }
  },
}
</script>
