<template>
  <v-row class="vehicle-info-panel">
    <v-col cols="12">
      <v-card>
        <v-card-title class="justify-center">
          <div class="text-center">
            Vehicle ID:
            <v-chip label small>
              {{ vehicle.vehicle_id }}
            </v-chip>
          </div>
        </v-card-title>

        <v-card-text v-if="vehicleHasPosition" class="d-flex flex-wrap mt-2 px-4">
          <v-row>
            <v-col>
              <MglMap
                ref="map"
                :access-token="$config.mapbox.accessToken"
                :track-resize="true"
                :touch-zoom-rotate="true"
                :scroll-zoom="true"
                :attribution-control="false"
                :map-style="$config.mapbox.style"
                :zoom="$config.mapbox.zoom"
                :center="[vehicle.status.position.longitude, vehicle.status.position.latitude]"
              >
                <template>
                  <MglMarker :coordinates="[vehicle.status.position.longitude, vehicle.status.position.latitude]">
                    <vehicle-marker slot="marker" :route="route" :position="vehicle.status.position"></vehicle-marker>
                  </MglMarker>
                </template>
              </MglMap>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-list>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="5">
                  <span class="font-weight-medium me-2">Device ID:</span>
                </v-col>
                <v-col cols="7">
                  <span class="text--secondary">{{ vehicle.status.device_id }}</span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="5">
                  <span class="font-weight-medium me-2">Status:</span>
                </v-col>
                <v-col cols="7">
                  <span v-if="vehicle.status.status" class="text--secondary">{{ vehicle.status.status }}</span>
                  <span v-else class="text--secondary">Unknown</span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="5">
                  <span class="font-weight-medium me-2">Device timestamp:</span>
                </v-col>
                <v-col cols="7">
                  <span v-if="vehicle.status.device_timestamp" class="text--secondary">{{
                    formatDate(vehicle.status.device_timestamp, "en-NZ")
                  }}</span>
                  <span v-else class="text--secondary">Unknown</span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="5">
                  <span class="font-weight-medium me-2">Position:</span>
                </v-col>
                <v-col cols="7">
                  <span v-if="vehicleHasPosition" class="text--secondary">
                    {{ vehicle.status.position.latitude }},{{ vehicle.status.position.longitude }}
                  </span>
                  <span v-else class="text--secondary">Unknown</span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="5">
                  <span class="font-weight-medium me-2">Position timestamp:</span>
                </v-col>
                <v-col cols="7">
                  <span v-if="vehicle.status.position_timestamp" class="text--secondary">{{
                    formatDate(vehicle.status.position_timestamp, "en-NZ")
                  }}</span>
                  <span v-else class="text--secondary">Unknown</span>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

      <v-card class="mt-5">
        <v-card-text v-if="route">
          <h2 class="text-xl font-weight-semibold mb-2">On Trip</h2>
          <v-divider class="mt-2 mb-2"></v-divider>

          <v-list>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2">Trip:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ vehicle.status.trip.trip_id }}</span>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2">Route ID:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ route.route_id }}</span>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2">Route Code:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ route.route_short_name }}</span>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2">Route Name:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ route.route_long_name }}</span>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2">Description:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ route.route_desc }}</span>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2">Mode:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ routeTypePresentation(route.route_type).text }}</span>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-text v-else>
          <h2 class="text-xl font-weight-semibold mb-2">Not currently on trip</h2>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCheck,
  mdiBriefcaseVariantOutline,
  mdiCheckboxBlankCircle,
  mdiAccessPoint,
  mdiAccessPointOff,
  mdiClockTimeEightOutline,
  mdiCrosshairsQuestion,
  mdiDesktopClassic,
  mdiOpenInNew,
} from "@mdi/js"
import { MglMap, MglMarker } from "vue-mapbox"
import { mapState } from "vuex"
import "mapbox-gl/dist/mapbox-gl.css"
import VehicleMarker from "@/components/vehicle/Marker.vue"
import globalHelpers from "@/mixins/globalHelpers"

export default {
  components: {
    MglMap,
    MglMarker,
    VehicleMarker,
  },
  mixins: [globalHelpers],

  computed: {
    ...mapState({ vehicle: state => state.selected_vehicle, routes: state => state.gtfs.routes }),
    route() {
      let route
      if (this.vehicle.status?.trip?.route_id) {
        route = this.routes.find(r => r.route_id.toString() === this.vehicle.status.trip.route_id.toString())
      }

      return route
    },
    vehicleHasPosition() {
      return !!this.vehicle.status?.position?.longitude
    },
  },

  setup() {
    // ui
    return {
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiAccessPoint,
        mdiAccessPointOff,
        mdiClockTimeEightOutline,
        mdiCrosshairsQuestion,
        mdiDesktopClassic,
        mdiOpenInNew,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/next-stop/components/map.scss";

.mgl-map-wrapper {
  height: 15rem;
  border-radius: 20px;
  overflow: hidden;
}
</style>
