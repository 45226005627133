<template>
  <v-row class="vehicle-screenshot-panel">
    <v-col cols="12">
      <v-card>
        <v-card-title class="justify-center"> Screenshot </v-card-title>

        <v-card-text v-if="is_loading" class="d-flex flex-wrap px-4">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </v-card-text>
        <v-card-text v-else-if="screenshot" class="d-flex flex-wrap px-4">
          <div class="mb-4"><b>Last Updated:</b> {{ formatDate(screenshot.last_modified, "en-NZ") }}</div>
          <a style="max-width: 100%" @click.prevent="overlay = !overlay">
            <v-img :src="screenshot.screenshot" alt="Vehicle screenshot" />
            <v-overlay v-if="overlay" :absolute="false" z-index="7" class="screenshot__overlay">
              <v-img :src="screenshot.screenshot" contain alt="Vehicle screenshot" max-width="1024" />
            </v-overlay>
          </a>
        </v-card-text>
        <v-card-text v-else-if="is_loading" class="d-flex flex-wrap px-4"> Screenshot not found </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store"
import globalHelpers from "@/mixins/globalHelpers"

export default {
  mixins: [globalHelpers],

  props: {
    vehicleId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      is_loading: true,
      screenshot: null,
      overlay: false,
      liveUpdate: null,
    }
  },

  created() {
    this.fetchScreenshot().then(() => {
      this.is_loading = false

      this.liveUpdate = setInterval(async () => {
        await this.fetchScreenshot()
      }, 30 * 1000)
    })
  },

  beforeDestroy() {
    clearInterval(this.liveUpdate)
  },

  methods: {
    async fetchScreenshot() {
      try {
        const data = await store.dispatch("getVehicleScreenshot", this.vehicleId)

        if (!this.screenshot || this.screenshot.last_modified !== data.last_modified) {
          this.screenshot = data
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
.screenshot__overlay .v-overlay__content {
  max-width: 100%;
}
</style>
